<template>
  <div
    class="interface-facture"
    :class="{ 'interface-simulation-facture': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- SELLECT TYPE DATE -->
        <div class="select-input-vuetify" v-if="checkPermission('SFTHFTDDDDF')">
          <v-select
            v-model="type_date"
            :items="[
              { value: 'statue_date', text: 'statut de paiement' },
              { value: 'date_depot_dossier', text: 'date de dépôt' }
            ]"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Type de date"
            @change="handleChangeTypeDate"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
        <!-- DATE DEBUT -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('SFTHFTDDDDF')"
        >
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                @click:clear="handleDateTimePicker(null, 'date_debut')"
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @change="handleDateTimePicker($event, 'date_debut')"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('SFTHFTDDDDF')"
        >
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                @click:clear="handleDateTimePicker(null, 'date_fin')"
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker($event, 'date_fin')"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-progress-circular
          class="ml-2"
          v-if="getLoadingSimulationFactureFrais"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorSimulationFactureFrais" class="error">
            <ul
              v-if="Array.isArray(getErrorSimulationFactureFrais)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorSimulationFactureFrais"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSimulationFactureFrais }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            v-if="checkPermission('SFTHCS')"
            hiddenContent="true"
          />
        </div>
        <!-- REGENERER FACTURE  -->
        <regenerer-frais-simulation
          v-if="checkPermission('SFTHRD')"
          :dataToUse="computedSimulationFactureFrais"
          @reloadData="handleFilter"
          :filterConfirmation="filterConfirmation"
          :filter="computedFilter"
        />
        <!-- LISTE DOSSIERS -->
        <list-dossier
          v-if="checkPermission('SFTHCDD')"
          :dataToUse="computedDataSimCheck"
        />
        <!-- VEROUILLAGE GLOBALE  -->
        <verouillage-globale
          v-if="computedDataCheck > 0 && checkPermission('SFTHMEC')"
          :dataToUse="computedSimulationFactureFrais"
        />
        <!-- EXPORT -->
        <export
          v-if="checkPermission('SFTHSDX')"
          :filter="computedFilterExport"
        />
        <!-- FILTER -->
        <v-tooltip
          bottom
          color="#311B92"
          v-if="
            checkPermission('SFTHFMF') ||
              checkPermission('SFTHFV') ||
              checkPermission('SFTHFCL') ||
              checkPermission('SFTHFFS') ||
              checkPermission('SFTHFL') ||
              checkPermission('SFTHFCO') ||
              checkPermission('SFTHFSF')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- MASTER FILIALE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('SFTHFMF')"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  v-model="isLocalStorageMasterFiliale"
                  :items="[...getListMasterSimulationDync]"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  :persistent-placeholder="true"
                  label="Master Filiale"
                  required
                  return-object
                  item-text="name"
                  dense
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune Master filiale trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageMasterFiliale.length - 0 }} Master(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('SFTHFV')"
                  v-model="isLocalStorageSupport"
                  :items="[
                    { name: 'MASTER FILIALE', id: 0 },
                    ...getListSupportSimulationDync
                  ]"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  :persistent-placeholder="true"
                  label="Société"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageSupport.length - 0 }} société(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('SFTHFCL')"
                  v-model="isLocalStorageFiliale"
                  :items="[...getListInstallagteurSimulationDync]"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  :persistent-placeholder="true"
                  label="Client"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageFiliale.length - 0 }} client(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- FAMILLE -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('SFTHFFS')"
                  v-model="isLocalStorageFamile"
                  :items="getListFamilleConfigFrias"
                  item-text="familly"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                  hide-details
                  class="mb-4"
                  label="Famille"
                  :loading="getLoadingFamilleConfig"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
                <!-- LOT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('SFTHFL')"
                  v-model="isLocalStorageLots"
                  :items="[...getListLotsSimulationDync]"
                  item-text="text"
                  item-value="value"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  :persistent-placeholder="true"
                  label="Lot"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun lot trouvé"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageLots.length - 0 }} Lot(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!--CONFIRMATION-->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('SFTHFCO')"
                  v-model="isLocalStorageConfirmation"
                  :items="[
                    { value: 'oui', text: 'Oui' },
                    { value: 'non', text: 'Non' }
                  ]"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Confirmation"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
                <!-- STATUTS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('SFTHFSF')"
                  v-model="isLocalStorageStatut"
                  :items="listStatuts"
                  item-text="name"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  item-color="#704ad1"
                  color="#704ad1"
                  label="Statut"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="index < 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statut)
                      <span hidden>{{ item.title }}</span>
                    </span>
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-simulation">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span v-if="type_date != null" class="item-filter-style"
            >Type de date : {{ type_date | FormateFilter }}</span
          >
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >

          <span v-if="filterConfirmation != null" class="item-filter-style"
            >Confirmation : {{ filterConfirmation.text }}</span
          >
          <span v-if="filterFamilly != null" class="item-filter-style"
            >Famille : {{ filterFamilly.familly }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- MASTER FILIALE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterMasterFiliale.length"
          class="space-table-filter-simulation-fac"
        >
          <span class="title-block-chip-filter">Master filiale : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.id"
                v-for="s in filterMasterFiliale"
                close
                @click:close="
                  remove(
                    s,
                    'filterMasterFiliale',
                    'handleFilter',
                    'isLocalStorageMasterFiliale'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterSupport.length"
          class="space-table-filter-simulation-fac"
        >
          <span class="title-block-chip-filter ml-3">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.id"
                v-for="v in filterSupport"
                close
                @click:close="
                  remove(
                    v,
                    'filterSupport',
                    'handleFilter',
                    'isLocalStorageSupport'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterFiliale.length"
          class="space-table-filter-simulation-fac"
        >
          <span class="title-block-chip-filter ml-3">Client : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.id"
                v-for="v in filterFiliale"
                close
                @click:close="
                  remove(
                    v,
                    'filterFiliale',
                    'handleFilter',
                    'isLocalStorageFiliale'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="lots.length"
          class="space-table-filter-simulation-fac"
        >
          <span class="title-block-chip-filter">Lot : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s"
                v-for="s in lots"
                close
                @click:close="
                  remove(s, 'lots', 'handleFilter', 'isLocalStorageLots')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut.length"
          class="space-table-filter-simulation-fac"
        >
          <span class="title-block-chip-filter">Statut : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.value"
                v-for="s in statut"
                close
                @click:close="
                  remove(s, 'statut', 'handleFilter', 'isLocalStorageStatut')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE SIMULATION -->
      <div
        class="table-simulation-fac"
        :class="{
          'padding-table': returnTableFiltre
        }"
      >
        <v-data-table
          v-model="selectedTable"
          :headers="computedFields"
          :items="computedSimulationFactureFraisTable"
          :items-per-page="perPage"
          :class="{
            'height-filter-chips-table': returnTableChips && !returnTableFiltre,
            'height-filter-table': returnTableFiltre && !returnTableChips,
            'height-filter-tous': returnTableChips && returnTableFiltre
          }"
          class="table-simulation"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          item-key="id"
          :item-class="rowClass"
          :disable-filtering="true"
          hide-action
          :no-data-text="
            getLoadingSimulationFactureFrais
              ? 'Chargement...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!-- HEADERS TABLE -->
          <template v-slot:[`header.check_all`]="{ header }">
            <td class="custom-cell sticky-header-1">
              <span v-if="header.value != 'check_all'">
                {{ header.label }}</span
              >
              <div v-else>
                <v-checkbox
                  class="checked-global"
                  v-model="checkAll"
                  @change="checkAllFunction"
                  color="#704ad1"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
          </template>
          <!-- BODY TABLE -->
          <template v-slot:[`item.check_all`]="{ item }">
            <td class="custom-cell sticky-header-1">
              <template v-if="item.total">{{ item.count }}</template>
              <v-checkbox
                v-else
                class="checked-groupe"
                v-model="item.check"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                :disabled="item.facture == false"
                hide-details
              ></v-checkbox>
            </td>
          </template>
          <template v-slot:[`item.numero_dossier`]="{ item }">
            <td class="custom-cell sticky-header-2">
              <font-awesome-icon
                v-if="!item.total"
                icon="circle"
                :class="{
                  'red-color-icon': item.project_confirmation == false,
                  'green-color-icon': item.project_confirmation == true
                }"
              />
              {{ item.project_numero ? item.project_numero : '-' }}
            </td>
          </template>
          <template v-slot:[`item.nom_prenom`]="{ item }">
            <td class="custom-cell">
              {{ item.project_client ? item.project_client : '-' }}
            </td>
          </template>
          <template v-slot:[`item.project_montant_final`]="{ item }">
            <td class="custom-cell">
              {{
                item.project_montant_final ? item.project_montant_final : '-'
              }}
              €
            </td>
          </template>
          <template v-slot:[`item.montant_net_ht`]="{ item }">
            <td class="custom-cell">
              {{ item.montant_net_ht ? item.montant_net_ht : '-' }} €
            </td>
          </template>
          <template v-slot:[`item.regie`]="{ item }">
            <td class="custom-cell">
              {{ item.project_regie ? item.project_regie : '-' }}
            </td>
          </template>
          <template v-slot:[`item.project_type_isolation`]="{ item }">
            <td class="custom-cell">
              {{
                item.project_type_isolation ? item.project_type_isolation : '-'
              }}
            </td>
          </template>
          <template v-slot:[`item.project_date_depot_dossier`]="{ item }">
            <td class="custom-cell">
              {{ item.project_date_depot_dossier | formateDate }}
            </td>
          </template>
          <template v-slot:[`item.project_statut_date`]="{ item }">
            <td class="custom-cell">
              {{ item.project_statut_date | formateDate }}
            </td>
          </template>
          <template v-slot:[`item.date_facture`]="{ item }">
            <td class="custom-cell">{{ item.date_facture | formateDate }}</td>
          </template>
          <template v-slot:[`item.project_installateur`]="{ item }">
            <td class="custom-cell">
              {{ item.project_installateur ? item.project_installateur : '-' }}
            </td>
          </template>

          <template v-slot:[`item.ht`]="{ item }">
            <td class="custom-cell">
              <template v-if="item.total">{{ item.ht }} €</template>
              <column-frais-simulation
                v-else
                :showCoef="false"
                :item="item"
                column="ht"
                columnCoef="ht"
                :updateFunction="updateCoefFraisSimulation"
                :editable="false"
                :showBlock="true"
                :uniteMontant="item.facture == false ? '' : '€'"
              />
            </td>
          </template>
          <template v-slot:[`item.tva`]="{ item }">
            <td class="custom-cell">{{ item.tva ? item.tva : '-' }}</td>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">{{ item.type ? item.type : '-' }}</td>
          </template>
          <template v-slot:[`item.ttc`]="{ item }">
            <td class="custom-cell">
              <template v-if="item.total">{{ item.ttc }} €</template>
              <column-frais-simulation
                v-else
                :showCoef="false"
                :item="item"
                column="ttc"
                columnCoef="ttc"
                :updateFunction="updateCoefFraisSimulation"
                :editable="false"
                :showBlock="true"
                :uniteMontant="item.facture == false ? '' : '€'"
              />
            </td>
          </template>
          <template v-slot:[`item.coef`]="{ item }">
            <td class="custom-cell">
              <template v-if="item.total">-</template>
              <column-frais-simulation
                v-else
                :showCoef="true"
                :item="item"
                column="coef"
                columnCoef="coef"
                :updateFunction="updateCoefFraisSimulation"
                :editable="
                  checkPermission('SFTHMC') && item.type == 'Facture proforma'
                "
                :showBlock="false"
                :uniteMontant="item.coef_type"
              />
            </td>
          </template>
          <template v-slot:[`item.num`]="{ item }">
            <td class="custom-cell flex-div">
              {{ item.num ? item.num : '-' }}
              <custom-iframe
                v-if="!item.total"
                :getDataPDF="getDataPDFSimulation"
                :data="item"
              />
            </td>
          </template>
          <template v-slot:[`item.objet`]="{ item }">
            <td class="custom-cell">{{ item.objet ? item.objet : '-' }}</td>
          </template>
          <template v-slot:[`item.validation`]="{ item }">
            <td class="custom-cell">
              <div>
                {{ item.loc }}
                <font-awesome-icon
                  icon="lock-open"
                  class="color-cdg ml-1"
                  v-if="item.lock == false && item.facture_id"
                />
                <font-awesome-icon
                  icon="lock"
                  class="color-cdg ml-1"
                  v-if="item.lock == true && item.facture_id"
                />
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="footer-style-table">
      <div
        class="text-center pagination-ta
      ble"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="7"
          @input="pagination"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import verouillageGlobale from './component/verouillageGlobale.vue';
import moment from 'moment';
import RegenererFraisSimulation from './component/regenererFraisSimulation.vue';

export default {
  name: 'Simulation-Facture-Frais',
  data() {
    return {
      selectedTable: [],
      menu: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      listStatuts: [
        { value: 'Simulation', name: 'Simulation' },
        {
          value: 'Créé',
          name: 'Créé'
        },
        { value: 'A payer', name: 'A payer' },
        { value: 'Payé', name: 'Payée' },
        { value: 'Annulée', name: 'Annulée' },
        { value: 'Payé en partie', name: 'Payé en partie' }
      ],
      statut: [],
      filterFamilly: null,
      heigthBlock: 0,
      type_date: 'date_depot_dossier',
      date_debut: null,
      date_fin: null,
      filterMasterFiliale: [],
      filterSupport: [],
      filterFiliale: [],
      lots: [],
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 20, text: 20 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      showCoef: false,
      checkAll: false,
      filterConfirmation: null,

      searchValue: null,
      isLocalStorageLots: [],
      isLocalStorageSupport: [],
      isLocalStorageStatut: [],
      isLocalStorageMasterFiliale: [],
      isLocalStorageFiliale: [],
      isLocalStorageConfirmation: null,
      isLocalStorageTypeDate: null,
      isLocalStorageFamile: null
    };
  },
  computed: {
    ...mapGetters([
      'getLoadingSimulationFactureFrais',
      'getSimulationFactureFraisData',
      'getErrorSimulationFactureFrais',
      'getSimulationTotalRow',
      'getLoaderDataFilterListSimulationDyn',
      'getListMasterSimulationDync',
      'getListSupportSimulationDync',
      'getListInstallagteurSimulationDync',
      'getListLotsSimulationDync',
      'checkPermission',
      'getListFamilleConfigFrias',
      'getLoadingFamilleConfig',
      'getShowNotifExportFile',
      'computedRouterLinkFacture',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.filterFiliale,
        this.lots,
        this.filterSupport,
        this.statut,
        this.filterMasterFiliale
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null ||
        this.type_date ||
        this.filterConfirmation != null ||
        this.filterFamilly != null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        this.filterFiliale.length ||
        this.lots.length ||
        this.filterSupport.length ||
        this.statut.length ||
        this.filterMasterFiliale.length
      ) {
        return true;
      }
      return false;
    },

    totalPages() {
      if (this.getSimulationTotalRow) {
        return Math.ceil(this.getSimulationTotalRow / this.perPage);
      }
      return this.getSimulationTotalRow;
    },
    TopFilter() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 75 + heigthBlock;
      return 'top : ' + `${hei}` + 'px!important ;';
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        // table.forEach(element => {
        //   table2.push(element.clientHeight);
        // });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.filterMasterFiliale?.length > 0 ||
        this.filterSupport?.length > 0 ||
        this.filterFiliale?.length > 0 ||
        this.lots?.length > 0 ||
        this.statut?.length > 0
      ) {
        return true;
      }
      return false;
    },
    formateValueFacture() {
      return function(data) {
        return data
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          .replaceAll(',', ' ');
      };
    },
    computedSimulationFactureFrais() {
      if (this.filterConfirmation != null) {
        let confirm = this.filterConfirmation == 'oui' ? true : false;
        return this.getSimulationFactureFraisData.filter(
          item => item.project_confirmation == confirm
        );
      }
      return this.getSimulationFactureFraisData;
    },
    computedDataCheck() {
      return this.computedSimulationFactureFraisTable.filter(
        item => item.check == true && item.facture_id
      ).length;
    },
    computedDataSimCheck() {
      return this.computedSimulationFactureFraisTable.filter(
        item => item.check == true
      );
    },
    computedSimulationFactureFraisTable() {
      let table = [];
      var somme = { ttc: 0, ht: 0, indexColor: 0, count: 0 };
      this.computedSimulationFactureFrais.map((item, index) => {
        if (
          index != 0 &&
          item.project_numero !=
            this.computedSimulationFactureFrais[index - 1].project_numero
        ) {
          let data = this.computedSimulationFactureFrais[index - 1];
          table.push({
            total: true,
            ht: this.formateValueFacture(Math.round(somme.ht * 100) / 100),
            ttc: this.formateValueFacture(Math.round(somme.ttc * 100) / 100),
            project_numero: data.project_numero,
            project_client: data.project_client,
            project_regie: data.project_regie,
            project_montant_final: data.project_montant_final,
            project_installateur: data.project_installateur,
            project_date_depot_dossier: data.project_date_depot_dossier,
            project_statut: data.project_statut,
            project_installateur: data.project_installateur,
            project_statut_date: data.project_statut_date,
            project_type_isolation: data.project_type_isolation,
            montant_net_ht: data.montant_net_ht,
            count: somme.count
          });
          somme = {
            ttc: 0,
            ht: 0,
            indexColor: somme.indexColor == 0 ? 1 : 0,
            count: 0
          };
        }
        if (item.facture) {
          somme.ht += parseFloat((item.ht_int + '').replace(' ', ''));
          somme.ttc += parseFloat((item.ttc_int + '').replace(' ', ''));
        }

        somme.count = somme.count + 1;
        item.index = somme.indexColor;
        table.push(item);
        if (index == this.computedSimulationFactureFrais.length - 1) {
          table.push({
            total: true,
            ht: this.formateValueFacture(Math.round(somme.ht * 100) / 100),
            ttc: this.formateValueFacture(Math.round(somme.ttc * 100) / 100),
            project_numero: item.project_numero,
            project_client: item.project_client,
            project_regie: item.project_regie,
            project_montant_final: item.project_montant_final,
            project_installateur: item.project_installateur,
            project_date_depot_dossier: item.project_date_depot_dossier,
            project_statut: item.project_statut,
            project_installateur: item.project_installateur,
            project_statut_date: item.project_statut_date,
            project_type_isolation: item.project_type_isolation,
            count: somme.count
          });
          somme = {
            ttc: 0,
            ht: 0,
            indexColor: somme.indexColor == 0 ? 1 : 0,
            count: 0
          };
        }
      });
      return table;
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'numero_dossier',
          text: 'Numéro de dossier',
          class: 'sticky-header-2',
          show: true,
          sortable: false
        },

        {
          value: 'nom_prenom',
          text: 'Nom et Prénom',
          editable: false,
          show: this.checkPermission('SFTHCNEP'),
          sortable: false
        },
        {
          value: 'project_installateur',
          text: 'Filiale Installateur',
          show: this.checkPermission('SFTHACFI'),
          sortable: false
        },
        {
          value: 'regie',
          text: 'Régie',
          show: this.checkPermission('SFTHACR'),
          sortable: false
        },
        {
          value: 'project_type_isolation',
          text: 'Type isolation',
          show: this.checkPermission('SFTHACTI'),

          sortable: false
        },
        {
          value: 'project_montant_final',
          text: 'Montant min',
          editable: false,
          show: this.checkPermission('SFTHACMM'),
          sortable: false
        },
        {
          value: 'montant_net_ht',
          text: 'Montant HT Dossier',
          editable: false,
          show: this.checkPermission('SFTHACMHTD'),
          sortable: false
        },
        {
          value: 'project_date_depot_dossier',
          text: 'Date de dépôt',
          show: this.checkPermission('SFTHACDDD'),
          sortable: false
        },
        {
          value: 'project_statut',
          text: 'Statut de paiement',
          show: this.checkPermission('SFTHACSDP'),
          sortable: false
        },
        {
          value: 'project_statut_date',
          text: 'Date de paiement',
          show: this.checkPermission('SFTHACDDP'),
          sortable: false
        },
        {
          value: 'type',
          text: 'Type de document',
          show: this.checkPermission('SFTHACTDD'),
          sortable: false
        },
        {
          value: 'num',
          text: 'Numéro de facture',
          show: this.checkPermission('SFTHACNDF'),
          sortable: false
        },
        {
          value: 'vendeur',
          text: 'Société',
          show: this.checkPermission('SFTHACV'),
          sortable: false
        },
        {
          value: 'client',
          text: 'Client',
          show: this.checkPermission('SFTHACCL'),
          sortable: false
        },
        {
          value: 'objet',
          text: 'Objet',
          show: this.checkPermission('SFTHACOB'),
          sortable: false
        },
        {
          value: 'ht',
          text: 'Montant HT',
          show: this.checkPermission('SFTHACMHT'),
          sortable: false
        },
        {
          value: 'tva',
          text: 'TVA',
          show: this.checkPermission('SFTHACTVA'),
          sortable: false
        },
        {
          value: 'ttc',
          text: 'Montant TTC',
          show: this.checkPermission('SFTHACMTTC'),
          sortable: false
        },
        {
          value: 'coef',
          text: 'Unité',
          show: this.checkPermission('SFTHACCOEF'),
          sortable: false
        },
        {
          value: 'statue',
          text: 'Statut de facture',
          show: this.checkPermission('SFTHACSDF'),
          sortable: false
        },
        {
          value: 'date_facture',
          text: 'Date de création de la facture',
          show: this.checkPermission('SFTHACDDCDLF'),
          sortable: false
        },
        {
          value: 'validation',
          text: '',
          show: this.checkPermission('SFTHACVER'),
          sortable: false
        }
      ];
      return fields.filter(item => item.show == true);
    },
    computedFilter() {
      let params = { type_date: this.type_date };

      if (this.date_debut != null) {
        params.date_debut = this.date_debut;
      }
      if (this.date_fin != null) {
        params.date_fin = this.date_fin;
      }
      if (this.filterMasterFiliale.length) {
        params.masters = this.filterMasterFiliale.map(item => item.id);
      }
      if (this.filterSupport?.length) {
        params.supports = this.filterSupport.map(item => item.id);
      }
      if (this.filterFiliale?.length) {
        params.clients = this.filterFiliale.map(item => item.id);
      }
      if (this.lots?.length) {
        params.lots = this.lots;
      }
      if (this.filterConfirmation != null) {
        params.confirmation = this.filterConfirmation;
      }
      if (this.filterFamilly != null) {
        params.support_familly = this.filterFamilly.id;
      }
      if (this.searchValue != null) {
        params.search = this.searchValue;
      }
      if (this.statut.length) {
        params.statut = this.statut.map(item => item.value);
      }
      return params;
    },
    computedFilterExport() {
      let params = new FormData();
      params.append('type_date', this.type_date);
      if (this.date_debut != null) {
        params.append('date_debut', this.date_debut);
      }
      if (this.date_fin != null) {
        params.append('date_fin', this.date_fin);
      }
      if (this.filterMasterFiliale.length) {
        this.filterMasterFiliale.forEach((item, index) => {
          params.append('masters[' + index + ']', item.id);
        });
      }
      if (this.filterSupport?.length) {
        this.filterSupport.forEach((item, index) => {
          params.append('supports[' + index + ']', item.id);
        });
      }
      if (this.filterFiliale?.length) {
        this.filterFiliale.forEach((item, index) => {
          params.append('clients[' + index + ']', item.id);
        });
      }
      if (this.lots?.length) {
        this.lots.forEach((item, index) => {
          params.append('lots[' + index + ']', item);
        });
      }
      if (this.filterConfirmation != null) {
        params.append('confirmation', this.filterConfirmation);
      }
      if (this.filterFamilly != null) {
        params.append('support_familly', this.filterFamilly.id);
      }
      if (this.statut.length) {
        this.statut.forEach((item, index) => {
          params.append('statut[' + index + ']', item.value);
        });
      }
      return params;
    }
  },
  filters: {
    FormateFilter: value => {
      switch (value) {
        case 'statue_date':
          return 'statut de paiement';
        case 'date_depot_dossier':
          return 'date de dépôt';
      }
    },
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllSimulationFactureFrais',
      'updateCoefFraisSimulation',
      'confirmationDossierSimulation',
      'updateTauxFraisInInterface',
      'getAllDataForFilterDynamicSimulation',
      'getDataForRowSimulationDyncs',
      'getAllDossierForSimulationProjects',
      'getDataPDFSimulation',
      'fetchAllFammilleConfig'
    ]),
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      //ORIGIN FILTER
      this.lots = [];
      this.filterSupport = [];
      this.filterFamilly = null;
      this.filterFiliale = [];
      this.filterConfirmation = null;
      this.statut = [];
      this.filterMasterFiliale = [];
      //POPOVER FILTER
      this.isLocalStorageLots = [];
      this.isLocalStorageSupport = [];
      this.isLocalStorageStatut = [];
      this.isLocalStorageMasterFiliale = [];
      this.isLocalStorageFiliale = [];
      this.isLocalStorageConfirmation = null;
      this.isLocalStorageTypeDate = null;
      this.isLocalStorageFamile = null;
      this.handleFilter(true);
    },
    validerPopover() {
      //
      //SET FILTER DROM POPOVER
      //
      this.filterMasterFiliale = this.isLocalStorageMasterFiliale;
      this.filterSupport = this.isLocalStorageSupport;
      this.filterFiliale = this.isLocalStorageFiliale;
      this.filterFamilly = this.isLocalStorageFamile;
      this.lots = this.isLocalStorageLots;
      this.filterConfirmation = this.isLocalStorageConfirmation;
      this.statut = this.isLocalStorageStatut;
      //
      //END SET FILTER DROM POPOVER
      //
      this.handleFilter(true);
      this.menu = false;
    },
    handleLockFacture() {},
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowSimulationDyncs({
          item: data.item
          // supports: this.filterSupport
        });
      }
    },
    refreshFilter(refresh) {
      this.getAllDataForFilterDynamicSimulation({
        type_date: this.type_date,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        masters: this.filterMasterFiliale.map(item => item.id),
        confirmation: this.filterConfirmation,
        lots: this.lots,
        supports: this.filterSupport,
        refresh: refresh
      });
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    setLocalStorageSimulationFraisFacture() {
      localStorage.setItem(
        'simulation-frais-facture-dync',
        JSON.stringify({
          type_date: this.type_date,
          date_debut: this.date_debut,
          filterFamilly: this.filterFamilly,
          date_fin: this.date_fin,
          search: this.searchValue,
          filterFiliale: this.filterFiliale,
          filterMasterFiliale: this.filterMasterFiliale,
          filterSupport: this.filterSupport,
          confirmation: this.filterConfirmation,
          lots: this.lots,
          statut: this.statut
        })
      );
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.refreshFilter(['refreshClient', 'refreshMaster', 'refreshLots']);
      this.handleFilter();
    },
    handleChangeTypeDate() {
      this.refreshFilter(['refreshClient', 'refreshMaster', 'refreshLots']);
      this.handleFilter();
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
      );
    },
    handleChangeSupport() {
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.refreshFilter(['refreshLots', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeFamille() {
      this.refreshFilter(['refreshClient', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeLots() {
      this.refreshFilter(['refreshClient', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeConfirmation() {
      // this.filterFiliale = [];
      // this.filterMasterFiliale = [];
      // this.filterSupport = [];
      this.refreshFilter([]);
      this.handleFilter();
    },
    handleChangeMasterFiliale() {
      // this.filterFiliale = [];
      // this.filterSupport = [];
      this.refreshFilter(['refreshClient', 'refreshLots']);
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    handleFilter() {
      this.setLocalStorageSimulationFraisFacture();
      this.$nextTick(async () => {
        this.getAllSimulationFactureFrais({
          page: this.page,
          perPage: this.perPage,
          date_debut: this.date_debut,
          filterFamilly: this.filterFamilly,
          date_fin: this.date_fin,
          filterFiliale: this.filterFiliale,
          filterMasterFiliale: this.filterMasterFiliale,
          search: this.searchValue,
          support: this.filterSupport,
          confirmation: this.filterConfirmation,
          type_date: this.type_date,
          lots: this.lots,
          statut: this.statut
        });
        this.getAllDossierForSimulationProjects({
          page: this.page,
          perPage: this.perPage,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filterFiliale: this.filterFiliale,
          filterMasterFiliale: this.filterMasterFiliale,
          search: this.searchValue,
          support: this.filterSupport,
          confirmation: this.filterConfirmation,
          type_date: this.type_date,
          lots: this.lots,
          statut: this.statut
        });
      });
    },
    rowClass(item) {
      if (item.facture == false) return 'ligneYellow';
      if (item.total) return 'ligneLogo1';
    },
    checkAllFunction() {
      this.computedSimulationFactureFrais.map(item => {
        item.check = item.facture == false ? false : this.checkAll;
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    }
  },
  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    listDossier: () => import('./component/listDossier.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    columnFraisSimulation: () =>
      import('./component/columnFraisSimulation.vue'),
    RegenererFraisSimulation,
    export: () => import('./component/export.vue'),
    verouillageGlobale,
    customIframe: () => import('@/views/component/customIframe')
  },
  async mounted() {
    if (localStorage.getItem('simulation-frais-facture-dync')) {
      let localVar = JSON.parse(
        localStorage.getItem('simulation-frais-facture-dync')
      );
      //
      //SET FILTER FROM LOCAL STORGE
      //
      this.date_debut = localVar.date_debut ? localVar.date_debut : null;
      this.date_fin = localVar.date_fin ? localVar.date_fin : null;
      this.filterMasterFiliale = localVar.filterMasterFiliale
        ? localVar.filterMasterFiliale
        : [];
      this.isLocalStorageMasterFiliale = this.filterMasterFiliale;
      this.filterFiliale = localVar.filterFiliale ? localVar.filterFiliale : [];
      this.isLocalStorageFiliale = this.filterFiliale;
      this.filterSupport = localVar.filterSupport ? localVar.filterSupport : [];
      this.isLocalStorageSupport = this.filterSupport;
      this.searchValue = localVar.search ? localVar.search : null;
      this.filterConfirmation = localVar.confirmation
        ? localVar.confirmation
        : null;
      this.isLocalStorageConfirmation = this.filterConfirmation;
      this.type_date = localVar.type_date ? localVar.type_date : null;
      this.lots = localVar.lots ? localVar.lots : [];
      this.isLocalStorageLots = this.lots;
      this.statut = localVar.statut ? localVar.statut : [];
      this.isLocalStorageStatut = this.statut;
      this.filterFamilly = localVar.filterFamilly
        ? localVar.filterFamilly
        : null;
      this.isLocalStorageFamile = this.filterFamilly;
    } else {
      var now = moment();
      this.date_debut = moment(now)
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.setLocalStorageSimulationFraisFacture();
    }
    this.refreshFilter([
      'refreshClient',
      'refreshLots',
      'refreshSupports',
      'refreshMaster'
    ]);
    this.fetchAllFammilleConfig({ blocked: false });
    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.similation-facture-frais {
  .entete {
    padding: 5px 10px;
  }
  // .body-box-rapport-resize {
  // max-height: calc(100vh - (174 + var(--heigthBlock)) px) !important;
  // height: calc(100vh - (174 + var(--heigthBlock)) px) !important;
  // }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableSimulationFactureFrais {
      // max-height: calc(100vh - 220px) !important;
      // height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
    // .resizeTableSimulation {
    // max-height: calc(100vh - 220px) !important;
    // height: calc(100vh - 220px) !important;
    // }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTableSimulationFactureFrais td,
.customTableSimulationFactureFrais th {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}

.table-rapport-style .customTableSimulationFactureFrais {
  .colhead-num-dossier-th,
  .col-num-dossier-th {
    left: 30px !important;
  }
  .thvalidation,
  .thvalidation {
    width: 50px !important;
  }
  td,
  th {
    font-size: 9px;
    width: 107px;
    min-width: 107px;
  }
  .btn-anomalie {
    padding: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  .background-week {
    justify-content: start !important;
    // background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      // width: 100px;
      // min-width: 100px;
      padding: 8px 5px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }

    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff !important;
      display: table-cell;
    }
  }
}
.blok-service-style {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.visibility-stiky-row-ssetails {
  background: #ededed;
  border: none !important;
}
.tr-vide {
  line-height: 25px;
}

.width-family-chip {
  width: auto !important;
}
.col_stiky_num_simulation {
  position: sticky;
  left: 30px;
}
.th_stiky_num_simulation {
  position: sticky;
  left: 30px;
  z-index: 20 !important;
}
.space-table-filter-simulation-fac {
  padding-top: 0px;
}
.table-simulation-fac {
  &.padding-table {
    padding-top: 19px;
  }
}
</style>
